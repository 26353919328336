body {
  background-color: black;
  color: white;
}

h1 { 
  color: white;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 80px; 
  font-weight: bold; 
  letter-spacing: -1px; 
  line-height: 1; 
  text-align: center; 
}

h2 { 
  color:white;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 40px; 
  font-weight: bold; 
  letter-spacing: -1px; 
  line-height: 1; 
  text-align: center; 
}

.posts {
    width: 60%;
    margin: 16px auto;
    border: 1px solid rgb(63, 63, 63);
    border-radius: 20px;
    padding: 16px;
    box-shadow: 0 2px 3px black;
    margin-top: 16px;
    text-align: center;

  }
  
.posts:hover {
  background: rgb(233, 231, 231);
  box-shadow: 0 2px 3px black;
  transition: 0.5s;
  
}
  
ul {
    padding-left: 0;
    list-style: none;
  }
  
ul li {
    margin-bottom: 1rem;
  }
  
ul li span {
    display: block;
  }

img {

    background: #fff;
    margin: 16px auto;
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 16px;
    /* -webkit-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    -moz-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52); */
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 0 2px 3px #eee; */
    margin-top: 16px;
    text-align: center;
  }

img:hover {
    background: rgba(255, 60, 0, 0.815);
    /* box-shadow: 0 4px 6px #eee; */
    box-shadow: 0 4px 8px 0 black, 0 6px 20px 0 black;
    transition: 0.3s;
    /* -webkit-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    -moz-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52); */

    
  }

  .button {
    background-color: azure;
    padding: 16px;
    border: 1px solid blue;
    border-radius: 5px;
    font: inherit;
    color: #000;
    cursor: pointer;

  }

  .button:hover {
    background-color: blue;
    padding: 16px;
    border: 1px solid blue;
    border-radius: 5px;
    font: inherit;
    color: #fff;
    cursor: pointer;

  }

