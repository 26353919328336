body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App_app__pit5Z {
  text-align: center;
}

.App_logo__OeGmR {
  -webkit-animation: App_App-logo-spin__QewP3 infinite 20s linear;
          animation: App_App-logo-spin__QewP3 infinite 20s linear;
  height: 40vmin;
}

.App_header__kCh8z {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App_link__1I6n9 {
  color: #61dafb;
}

@-webkit-keyframes App_app-logo-spin__2SOit {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App_app-logo-spin__2SOit {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.App_ah1__A-bUC {
   color: rgb(250, 250, 250);
   font-family: 'Helvetica Neue', sans-serif; 
   font-size: 100px; 
   font-weight: bold; 
   letter-spacing: -1px; 
   line-height: 1; 
   text-align: center; }

.App_ah2__39ExD { 
  color: rgb(250, 250, 250); 
  font-family: 'Open Sans', sans-serif; 
  font-size: 30px; font-weight: 300; 
  line-height: 32px; 
  margin: 0 0 72px; 
  text-align: center; }

.App_ap__1P1rv { 
  color: rgb(97, 97, 97); 
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 16px; 
  letter-spacing: 1px; 
  line-height: 24px; 
  margin: 0 0 24px; 
  text-align: justify; 
  text-justify: inter-word;
 }


 .App_box__7B5AD {
  width: 60px;
  height: 60px;
  background: #1cf0ff;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  margin-bottom: 80px;
}

.App_box__7B5AD:hover {
  width: 60px;
  height: 60px;
  background: #fff;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  margin-bottom: 80px;
}

body {
  background-color: black;
  color: white;
}

h1 { 
  color: white;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 80px; 
  font-weight: bold; 
  letter-spacing: -1px; 
  line-height: 1; 
  text-align: center; 
}

h2 { 
  color:white;
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 40px; 
  font-weight: bold; 
  letter-spacing: -1px; 
  line-height: 1; 
  text-align: center; 
}

.PostsList_posts__3UJr9 {
    width: 60%;
    margin: 16px auto;
    border: 1px solid rgb(63, 63, 63);
    border-radius: 20px;
    padding: 16px;
    box-shadow: 0 2px 3px black;
    margin-top: 16px;
    text-align: center;

  }
  
.PostsList_posts__3UJr9:hover {
  background: rgb(233, 231, 231);
  box-shadow: 0 2px 3px black;
  transition: 0.5s;
  
}
  
ul {
    padding-left: 0;
    list-style: none;
  }
  
ul li {
    margin-bottom: 1rem;
  }
  
ul li span {
    display: block;
  }

img {

    background: #fff;
    margin: 16px auto;
    border: 1px solid #eee;
    border-radius: 20px;
    padding: 16px;
    /* -webkit-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    -moz-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52); */
    
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* box-shadow: 0 2px 3px #eee; */
    margin-top: 16px;
    text-align: center;
  }

img:hover {
    background: rgba(255, 60, 0, 0.815);
    /* box-shadow: 0 4px 6px #eee; */
    box-shadow: 0 4px 8px 0 black, 0 6px 20px 0 black;
    transition: 0.3s;
    /* -webkit-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    -moz-box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52);
    box-shadow: -1px 5px 20px -4px rgba(0,0,0,0.52); */

    
  }

  .PostsList_button__38tiK {
    background-color: azure;
    padding: 16px;
    border: 1px solid blue;
    border-radius: 5px;
    font: inherit;
    color: #000;
    cursor: pointer;

  }

  .PostsList_button__38tiK:hover {
    background-color: blue;
    padding: 16px;
    border: 1px solid blue;
    border-radius: 5px;
    font: inherit;
    color: #fff;
    cursor: pointer;

  }


