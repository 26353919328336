
.app {
  text-align: center;
}

.logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ah1 {
   color: rgb(250, 250, 250);
   font-family: 'Helvetica Neue', sans-serif; 
   font-size: 100px; 
   font-weight: bold; 
   letter-spacing: -1px; 
   line-height: 1; 
   text-align: center; }

.ah2 { 
  color: rgb(250, 250, 250); 
  font-family: 'Open Sans', sans-serif; 
  font-size: 30px; font-weight: 300; 
  line-height: 32px; 
  margin: 0 0 72px; 
  text-align: center; }

.ap { 
  color: rgb(97, 97, 97); 
  font-family: 'Helvetica Neue', sans-serif; 
  font-size: 16px; 
  letter-spacing: 1px; 
  line-height: 24px; 
  margin: 0 0 24px; 
  text-align: justify; 
  text-justify: inter-word;
 }


 .box {
  width: 60px;
  height: 60px;
  background: #1cf0ff;
  transform-origin: 50% 50%;
  margin-bottom: 80px;
}

.box:hover {
  width: 60px;
  height: 60px;
  background: #fff;
  transform-origin: 50% 50%;
  margin-bottom: 80px;
}
